import React from 'react';
import styled from 'styled-components';

import bannerLine from '../../../images/svg/banner-line.svg';
import people from '../../../images/svg/banner-people.svg';

const BannerContainer = styled.div`
      min-height: calc(100vh - 56px);
      background-color: #293189;
  `;

const RegisterBanner = () => {

  return (
    <BannerContainer className="w-full md:px-30 px-5 py-5 relative bg-cover bg-center bg-no-repeat -z-1 text-white flex flex-col justify-center items-center md:items-start md:grid md:grid-cols-2 gap-15">
      <div className='col-span-1 md:pr-40 md:pt-20 text-center md:text-left' >
        <div className="text-7xl font-bold mb-3">Seu futuro <br/> está aqui!</div>
        <div className='text-2xl md:pr-15'>Complete sua inscrição no formulário abaixo:</div>
      </div>
      <div className='col-span-1 hidden md:block'>
      </div>

      {/* lines */}
      <img className='hidden md:block absolute h-full right-30 -z-1' src={bannerLine} alt="Banner lines" />

      {/* people */}
      <img className='hidden md:block absolute h-3/4 right-50 bottom-20 -z-1' src={people} alt="Banner people" />
    </BannerContainer>
  )
}

export default RegisterBanner