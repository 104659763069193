import React from 'react';

import Layout from '../components/layout';
import RegisterBanner from '../components/Register/Banner';
import RegisterForm from '../components/Register/Form';

const Register = ({location}) => {
  return (
    <Layout footerColor={true}>
      <RegisterBanner />
      <RegisterForm params={location?.state} />
    </Layout>
  )
}

export default Register