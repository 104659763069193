import axios from "axios";
import { navigate } from "gatsby";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import arrow from "../../../images/svg/arrow.svg";

const RegisterForm = ({ params }) => {
  const api = "https://pf-api.prouniversidade.com.br";
  //const api = 'http://localhost/pro-floripa-new/pf-api';

  const { name, surname, email, phone, course, pole } = params ?? {};

  const [state, setState] = useState({
    name: name ?? "",
    surname: surname ?? "",
    email: email ?? "",
    phone: phone ?? "",
    course: course ?? "",
    pole: pole ?? "",
    cep: "",
    cpf: "",
    address: "",
    number: "",
    district: "",
    city: "",
    uf: "",
  });

  const handleInputChange = (event) => {
    state[event.target.name] = event.target.value;
    setState({ ...state });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${api}/register-unisul`, {
        ...state,
        campaign: "unisul-digital-matricula",
      })
      .then((res) => {
        console.log("Formulário enviado com sucesso!", res);
      })
      .catch((err) => {
        console.log("Erro ao enviar formulário", err);
      });

    navigate("/thanks");
  };

  return (
    <div className="relative">
      <div className="md:w-4/5 w-11/12 mx-auto md:-mt-50 -mt-30 z-99999 bg-accent shadow-md py-10 px-10 rounded-xl mb-20">
        <form className="form" onSubmit={handleOnSubmit}>
          <div className="md:grid md:grid-cols-2 md:gap-5">
            {/* name */}
            <div className="flex flex-col pb-4 col-span-1">
              <input
                id="name"
                name="name"
                value={state.name}
                onChange={handleInputChange}
                placeholder="Nome"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>

            {/* surname */}
            <div className="flex flex-col pb-4 col-span-1">
              <input
                id="surname"
                name="surname"
                value={state.surname}
                onChange={handleInputChange}
                placeholder="Sobrenome"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-5">
            {/* cpf */}
            <div className="flex flex-col pb-4">
              <InputMask
                mask="999.999.999-99"
                maskChar={null}
                value={state.cpf}
                onChange={handleInputChange}
                id="cpf"
                name="cpf"
                placeholder="CPF"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>

            {/* phone */}
            <div className="flex flex-col pb-4">
              <InputMask
                mask="(99) 99999-9999"
                maskChar={null}
                value={state.phone}
                onChange={handleInputChange}
                id="phone"
                name="phone"
                placeholder="Celular"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>
          </div>

          {/* email */}
          <div className="flex flex-col pb-4">
            <input
              id="email"
              name="email"
              value={state.email}
              onChange={handleInputChange}
              placeholder="E-mail"
              className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
              type="email"
              required
            />
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-5">
            {/* cep */}
            <div className="flex flex-col pb-4">
              <InputMask
                mask="99999-999"
                maskChar={null}
                value={state.cep}
                onChange={handleInputChange}
                id="cep"
                name="cep"
                placeholder="CEP"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>

            {/* address */}
            <div className="flex flex-col pb-4">
              <input
                value={state.address}
                onChange={handleInputChange}
                id="address"
                name="address"
                placeholder="Endereço"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-5">
            {/* number */}
            <div className="flex flex-col pb-4">
              <input
                value={state.number}
                onChange={handleInputChange}
                id="number"
                name="number"
                placeholder="Número"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="number"
                required
              />
            </div>

            {/* district */}
            <div className="flex flex-col pb-4">
              <input
                value={state.district}
                onChange={handleInputChange}
                id="district"
                name="district"
                placeholder="Bairro"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-5">
            {/* city */}
            <div className="flex flex-col pb-4">
              <input
                value={state.city}
                onChange={handleInputChange}
                id="city"
                name="city"
                placeholder="Cidade"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>

            {/* UF */}
            <div className="flex flex-col pb-4">
              <InputMask
                mask="aa"
                maskChar={null}
                value={state.uf}
                onChange={handleInputChange}
                id="uf"
                name="uf"
                placeholder="UF"
                className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
                type="text"
                required
              />
            </div>
          </div>

          {/* course */}
          <div className="flex flex-col pb-4">
            <input
              id="course"
              name="course"
              value={state.course}
              onChange={handleInputChange}
              placeholder="Curso pretendido"
              className="rounded-xl border-primary bg-accent text-white placeholder-gray-200 placeholder-opacity-80"
              type="text"
              required
            />
          </div>

          {/* pole */}
          <div className="flex flex-col pb-4">
            <div className="text-white text-xl font-bold mb-3">Polo</div>
            <div className="flex items-center justify-around">
              {/* Floripa */}
              <div className="flex gap-2 itens-center">
                <input
                  id="pole"
                  name="pole"
                  value="Florianópolis"
                  checked={state.pole === "Florianópolis"}
                  onChange={handleInputChange}
                  type="radio"
                  required
                />
                <label className="text-primary text-xl font-bold">
                  Floripa
                </label>
              </div>

              {/* Palhoça */}
              <div className="flex gap-2 itens-center">
                <input
                  id="pole"
                  name="pole"
                  value="Palhoça"
                  checked={state.pole === "Palhoça"}
                  onChange={handleInputChange}
                  type="radio"
                  required
                />
                <label className="text-primary text-xl font-bold">
                  Palhoça
                </label>
              </div>

              {/* Biguaçu */}
              <div className="flex gap-2 itens-center">
                <input
                  id="pole"
                  name="pole"
                  value="Biguaçu"
                  checked={state.pole === "Biguaçu"}
                  onChange={handleInputChange}
                  type="radio"
                  required
                />
                <label className="text-primary text-xl font-bold">
                  Biguaçu
                </label>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bottom-3 mx-auto mt-5 bg-primary text-white py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden hover:bg-primary-900"
          >
            FINALIZAR MATRÍCULA!
          </button>
        </form>
      </div>

      {/* arrow */}
      <img
        className="hidden md:block absolute left-0 top-50 h-full -z-1"
        src={arrow}
        alt="arrow"
      />
      <img
        className="hidden md:block absolute right-0 top-50 h-full -z-1 transform -scale-x-1"
        src={arrow}
        alt="arrow"
      />
    </div>
  );
};

export default RegisterForm;
